import React from 'react';
import {graphql} from 'gatsby';
import Page from '../../site/layouts/Page';
import SEO from '../SEO/SEO';
import ArticlesListTemplate from './ArticlesListTemplate';
import PaginationEx from '../PaginationEx';

const ArticlesList = props => {
  const {data, pageContext} = props;
  const {
    currentPage,
    hasNextPage,
    hasPreviousPage,
    baseLink,
    pagedLink,
  } = pageContext;
  const path = pagedLink || '/articles';
  const articles = data.ahp.paginated.nodes;
  if (Array.isArray(articles) && articles.length) {
    // Make the first article display as featured
    articles[0].displayMode = 'vertical';
  }
  return (
    <Page sidebar="articles">
      <SEO
        title="Lifestyle and Real Estate Articles"
        description="Blog articles about real estate and living in Austin, Cedar Park, Leander, Round Rock, Pflugerville, Manor, Dripping Springs Texas"
        pathname={path}
        keywords={[
          'Buying a house',
          'House for sale',
          'Live in Austin',
          'Rent in Austin',
          'Home improvement',
          'DIY projects',
        ]}
      />
      <ArticlesListTemplate articles={articles} />
      <PaginationEx
        indeterminate
        baseLink={baseLink || pagedLink}
        currentPage={currentPage}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
      />
    </Page>
  );
};

export default ArticlesList;

export const query = graphql`
  query ArticleListing($startCursor: String, $perPage: Int!) {
    ahp {
      paginated: featuredRecentArticles(
        paginate: {after: $startCursor, first: $perPage}
      ) {
        ...ArticleListItemFragment
      }
    }
  }
`;
